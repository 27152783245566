<template>
  <v-navigation-drawer
      v-model="drawer"
      width="400"
      app
      temporary
      right
      class="wost-drawer"
  >
    <div class="navigation-head">
      <span>{{ titleForm }}</span>
    </div>

    <div class="pl-3 pt-3 pr-3" style="height: 80vh;">
      <template>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
                v-model="filters.type_document_id"
                :items="types_documents"
                label="Tipo de documento"
                hide-details
                outlined
                :item-text="customText"
                item-value="id"
                clearable
                @change="handleSelectTypeDocument"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="filters.document" label="Documento" outlined hide-details></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="!is_company">
          <v-col cols="12">
            <v-text-field v-model="filters.name" label="Nombre" outlined hide-details></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="!is_company">
          <v-col cols="12">
            <v-text-field v-model="filters.surname" label="Apellidos" outlined hide-details></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="is_company">
          <v-col cols="12">
            <v-text-field v-model="filters.name" label="Razón Social" outlined hide-details></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="filters.email" label="Email" outlined hide-details></v-text-field>
          </v-col>
        </v-row>
      </template>
    </div>

    <div class="mt-3 pl-3 pt-3 pr-3">
      <template>
        <v-row>
          <v-col cols="6">
            <v-btn block height="40" dark color="red" @click="handleClearFilters">
              <v-icon size="16" class="mr-1">far fa-trash-alt</v-icon>
              Quitar filtros
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn dark color="blue" block height="40" @click="handleSearch">
              <v-icon size="16" class="mr-1">fas fa-search</v-icon>
              Filtrar datos
            </v-btn>
          </v-col>
        </v-row>
      </template>

    </div>

  </v-navigation-drawer>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import ArrayTools from '../../helpers/ArrayTools'

export default {
  name: 'FiltersPaginated',
  data() {
    return {
      drawer: false,
      titleForm: null,
      is_company: false,
      filters: {
        type_document_id: null,
        document: null,
        name: null,
        surname: null,
        email: null
      }
    }
  },

  computed: {
    ...mapState('documents', [
      'types_documents',
    ]),
    ...mapState('customers', [
      'filtersPaginated'
    ])
  },
  methods: {
    ...mapActions('customers', [
      'getCustomers',
      'changeFilterPaginated'
    ]),
    ...mapActions('documents', [
      'getTypesDocuments'
    ]),
    showForm(title) {
      this.titleForm = title
      this.drawer = !this.drawer
    },
    closeForm() {
      this.drawer = false
    },
    clearData() {
      this.is_company = false
      this.filters.type_document_id = null
      this.filters.document = null
      this.filters.name = null
      this.filters.surname = null
      this.filters.email = null
      this.changeFilterPaginated({...this.filters, paginated: true, itemsPerPage: 10})
    },

    customText(item) {
      let text = item.name
      return text
    },
    handleSelectTypeDocument(value) {
      if (value === undefined || value === null) {
        this.is_company = false
      } else {
        let typeDocument = ArrayTools.getTypeDocumentByID(this.types_documents, value)
        if (typeDocument.slug === 'ruc') {
          this.is_company = true
        } else {
          this.is_company = false
        }
      }
    },
    async handleClearFilters() {
      this.clearData()
      await this.getCustomers({
        ...this.filters,
        paginated: true,
        itemsPerPage: 10,
      })
    },
    async handleSearch() {
      this.changeFilterPaginated({...this.filters, paginated: true, itemsPerPage: 10})
      await this.getCustomers({...this.filters, paginated: true, itemsPerPage: 10})
      this.closeForm()
    }
  },
  mounted() {
    this.getTypesDocuments()
    this.changeFilterPaginated({
      category_id:null,
      itemsPerPage:null,
      main_storehouse:null,
      office_id:null,
      paginated:true,
      value_stock:null
    })
  }
}
</script>

<style scoped>

</style>