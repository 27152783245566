<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>Descargar registro de clientes</span>                         
        </div>

        <div class="pl-3 pt-3 pr-3" style="height: 80vh;">                
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="filters.type_document_id"
                            :items="types_documents"
                            label="Tipo de documento"
                            hide-details
                            outlined
                            :item-text="customText"
                            item-value="id"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="filters.user_id"
                            :items="users"
                            label="Usuario"
                            hide-details
                            outlined
                            :item-text="customText"
                            item-value="id"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </template>                    
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleCancel">
                            <v-icon size="16" class="mr-1">far fa-times-circle</v-icon>
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn dark 
                            color="blue" 
                            block 
                            height="40" 
                            :loading="processing"
                            @click="handleDownload">
                            <v-icon size="16" class="mr-1">fas fa-download</v-icon>
                            Descargar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>               
            
        </div>

    </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions} from 'vuex';

import ArrayTools from '../../helpers/ArrayTools';

import UsersApi from '../../apis/Users';

export default {
    name: 'FormDownload',
    data() {
        return {
            drawer: false,
            processing: false,
            titleForm: null,
            users: [],
            filters: {
                type_document_id: null,
                user_id: null
            }
        }
    },
    computed: {
        ...mapState('documents', [
            'types_documents'
        ]),
        ...mapState('customers', [
            'file_name'
        ])
    },
    methods: {
        ...mapActions('customers', [
            'getCustomers',
            'downloadCustomers'
        ]),
        ...mapActions('documents', [
            'getTypesDocuments'
        ]),
        showForm(title) {
            this.listUsers();
            this.clearData();
            this.titleForm = title
            this.drawer = !this.drawer
        },
        closeForm() {
            this.drawer = false
        },
        clearData() {
            this.filters.type_document_id = null;
        },
        customText(item){
            let text = item.name
            
            return text
        },
        handleCancel () {
            this.clearData();
            this.closeForm();
        },
        async handleDownload () {
            this.processing = true;
            let filters_data = Object.assign({}, this.filters);
            await this.downloadCustomers(filters_data);
            this.processing = false;
            if (this.file_name) {
                let url = process.env.VUE_APP_API_URL_BASE + '/' + this.file_name;
                window.open(url);
            }
        },
        async listUsers () {
            await UsersApi.getListUsers()
                .then( (response) => {
                    this.users = response.data;
                }).catch( (error) => {
                    this.users = [];
                });
        }
    },
    mounted() {
        this.getTypesDocuments();
    }
}
</script>

<style scoped>

</style>